import React from "react";
import { propTypes, defaultProps } from "./iconProps";

const CheckSmall = ({ className, color, size }) => (
  <span className={className}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      viewBox="0 -960 960 960"
      width={size}
      fill={color}
    >
      <path d="M400-304 240-464l56-56 104 104 264-264 56 56-320 320Z" />
    </svg>
  </span>
);

CheckSmall.propTypes = propTypes;

CheckSmall.defaultProps = defaultProps;

export default CheckSmall;
