import React from "react";
import { propTypes, defaultProps } from "./iconProps";

const ArrowExpandDown = ({ className, color, size }) => (
  <span className={className}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      viewBox="0 -960 960 960"
      width={size}
      fill={color}
    >
      <path d="M480-361q-8 0-15-2.5t-13-8.5L268-556q-11-11-11-28t11-28q11-11 28-11t28 11l156 156 156-156q11-11 28-11t28 11q11 11 11 28t-11 28L508-372q-6 6-13 8.5t-15 2.5Z" />
    </svg>
  </span>
);

ArrowExpandDown.propTypes = propTypes;

ArrowExpandDown.defaultProps = defaultProps;

export default ArrowExpandDown;
