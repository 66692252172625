import React from "react";
import HorizontalBar from "../../common/charts/HorizontalBar";
import ToggleSwitch from "../../common/ToggleSwitch";
import colors from "../../../frontend/stylesheets/common/theme/_colors.module.scss";

const { lightLavender, purpleBlue } = colors;

const MOCK_DATA = [
  {
    product: "Product A",
    Scrubbed: 139,
    "Not Scrubbed": 143,
  },
  {
    product: "Product B",
    Scrubbed: 79,
    "Not Scrubbed": 86,
  },
  {
    product: "Product C",
    Scrubbed: 104,
    "Not Scrubbed": 13,
  },
  {
    product: "Product D",
    Scrubbed: 140,
    "Not Scrubbed": 192,
  },
  {
    product: "Product E",
    Scrubbed: 5,
    "Not Scrubbed": 53,
  },
  {
    product: "Product F",
    Scrubbed: 183,
    "Not Scrubbed": 84,
  },
  {
    product: "Product G",
    Scrubbed: 105,
    "Not Scrubbed": 77,
  },
];

const DistributionOfProducts = () => {
  return (
    <div className="card">
      <div className="card__actions">
        <div className="card__title">Distribution of Products</div>
        <ToggleSwitch options={["$ Amount", "Lines"]} onSelection={() => {}} />
      </div>
      <HorizontalBar
        data={MOCK_DATA}
        showLegend={true}
        isLoading={false}
        keys={["Scrubbed", "Not Scrubbed"]}
        indexBy="product"
        colors={[purpleBlue, lightLavender]}
        height={278}
        noDataMessage="There is no data that matches your filter selection"
      />
    </div>
  );
};

export default DistributionOfProducts;
