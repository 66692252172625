import React from "react";
import { propTypes, defaultProps } from "./iconProps";

const Add = ({ className, color, width }) => (
  <span className={className}>
    <svg width={width} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.14286 6.85714H0.857143C0.614286 6.85714 0.410714 6.775 0.246429 6.61071C0.0821429 6.44643 0 6.24286 0 6C0 5.75714 0.0821429 5.55357 0.246429 5.38929C0.410714 5.225 0.614286 5.14286 0.857143 5.14286H5.14286V0.857143C5.14286 0.614286 5.225 0.410714 5.38929 0.246429C5.55357 0.0821429 5.75714 0 6 0C6.24286 0 6.44643 0.0821429 6.61071 0.246429C6.775 0.410714 6.85714 0.614286 6.85714 0.857143V5.14286H11.1429C11.3857 5.14286 11.5893 5.225 11.7536 5.38929C11.9179 5.55357 12 5.75714 12 6C12 6.24286 11.9179 6.44643 11.7536 6.61071C11.5893 6.775 11.3857 6.85714 11.1429 6.85714H6.85714V11.1429C6.85714 11.3857 6.775 11.5893 6.61071 11.7536C6.44643 11.9179 6.24286 12 6 12C5.75714 12 5.55357 11.9179 5.38929 11.7536C5.225 11.5893 5.14286 11.3857 5.14286 11.1429V6.85714Z"
        fill={color}
      />
    </svg>
  </span>
);

Add.propTypes = propTypes;

Add.defaultProps = defaultProps;

export default Add;
