import React from "react";
import HorizontalBar from "../../common/charts/HorizontalBar";
import ToggleSwitch from "../../common/ToggleSwitch";
import SelectSearch from "../../common/SelectSearch";
import colors from "../../../frontend/stylesheets/common/theme/_colors.module.scss";

const { highlightBlue, lightPurple, goldenYellow, vividGreen } = colors;

const MOCK_PRODUCT_OPTIONS = [
  {
    label: "All Products",
    value: "All Products",
  },
  {
    label: "Product A",
    value: "Product A",
  },
  {
    label: "Product B",
    value: "Product B",
  },
  {
    label: "Product C",
    value: "Product C",
  },
];

const MOCK_DATA = [
  {
    key: "Customer Average",
    "Scrub 1": 15,
    "Scrub 2": 3,
    "Scrub 3": 5,
    "Not Scrubbed": 77,
  },
  {
    key: "Last Invoice",
    "Scrub 1": 20,
    "Scrub 2": 3,
    "Scrub 3": 5,
    "Not Scrubbed": 72,
  },
  {
    key: "This Invoice",
    "Scrub 1": 7,
    "Scrub 2": 3,
    "Scrub 3": 5,
    "Not Scrubbed": 85,
  },
];

const ScrubResultsComparison = () => {
  return (
    <div className="card">
      <div className="card__actions">
        <div className="card__title">Scrub Results Comparison</div>
        <ToggleSwitch options={["$ Amount", "Lines"]} onSelection={() => {}} />
      </div>
      <div style={{ marginBottom: "20px" }}>
        <SelectSearch
          name="product"
          testId="product-filter"
          options={MOCK_PRODUCT_OPTIONS}
          defaultValue={MOCK_PRODUCT_OPTIONS[0]}
          placeholder="Product"
          value={MOCK_PRODUCT_OPTIONS[0]}
          size="md"
          onChange={() => {}}
        />
      </div>
      <HorizontalBar
        data={MOCK_DATA}
        showLegend={true}
        isLoading={false}
        keys={["Scrub 1", "Scrub 2", "Scrub 3", "Not Scrubbed"]}
        indexBy="key"
        colors={[highlightBlue, lightPurple, goldenYellow, vividGreen]}
        height={278}
        noDataMessage="There is no data that matches your filter selection"
        usePercentages
      />
    </div>
  );
};

export default ScrubResultsComparison;
