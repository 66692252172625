import React, { useState, useEffect, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import debounce from "debounce-promise";
import { createColumnHelper } from "@tanstack/react-table";
import TableVirtualized from "../common/TableVirtualized";
import MultiSelect from "../common/MultiSelect";
import SelectSearch from "../common/SelectSearch";
import Tooltip from "../common/Tooltip";
import Notice from "../common/Notice";
import { InfoOutlined } from "../common/icons";
import useFetch from "../hooks/useFetch";
import { extractValues } from "../utils/arrayUtils";
import { addCommasToNumber, formatToPercent } from "../utils/numberUtils";
import { numberSortFn } from "../utils/tanstackCustomFns";
import { createQueryString } from "../utils/urlUtils";

const filterTypes = {
  COVERED_ENTITIES: "coveredEntities",
  PRODUCTS: "products",
  MATCH_RATE: "matchRate",
  MONTH: "month",
};

const initialNoticeState = {
  kind: "error",
  open: false,
  message: "",
};

const initialFilters = {
  coveredEntities: [],
  products: [],
  matchRate: [],
};

const DEBOUNCE_TIME = 500;

const CEMatchRate = ({ coveredEntities, products, matchRates, months }) => {
  const [filters, setFilters] = useState({ ...initialFilters, month: months[0] });
  const [ceMatchRateDataNotice, setCeMatchRateDataNotice] = useState(initialNoticeState);
  const {
    get: getCeMatchRateData,
    isLoading,
    data: ceMatchRateData,
    error: ceMatchRateDataError,
  } = useFetch();

  const debouncedFetch = useCallback(debounce(getCeMatchRateData, DEBOUNCE_TIME), []);

  useEffect(() => {
    if (ceMatchRateDataError) {
      setCeMatchRateDataNotice({
        kind: "error",
        open: true,
        message: "Error retrieving covered entity match rate data",
      });
    }
  }, [ceMatchRateDataError]);

  useEffect(() => {
    const { coveredEntities, products, matchRate, month } = filters;
    const queryString = createQueryString({
      id_340b: extractValues(coveredEntities),
      product_name: extractValues(products),
      match_rate: extractValues(matchRate),
      month: month.value,
    });

    debouncedFetch(`/api/reports/covered_entity_match_rate_report${queryString}`);
  }, [filters]);

  const handleFilterChange = (filter, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filter]: value,
    }));
  };

  const hasFiltersApplied = Object.values(filters).some((filter) => filter.length > 0);

  const clearFilters = () =>
    setFilters((prevFilters) => ({ ...initialFilters, month: prevFilters.month }));

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper();

    return [
      columnHelper.accessor("id340b", {
        header: "340BID",
        cell: (info) => info.getValue(),
        size: 116,
      }),
      columnHelper.accessor("coveredEntity", {
        header: "Covered Entity",
        cellProps: { className: "fw-500" },
        cell: (info) => info.getValue(),
        size: 400,
      }),
      columnHelper.accessor("totalClaims", {
        header: "Total Claims",
        headerProps: { className: "align-right" },
        cellProps: { className: "align-right" },
        cell: (info) => addCommasToNumber(info.getValue()),
        sortingFn: numberSortFn,
        size: 156,
      }),
      columnHelper.accessor("totalMatches", {
        header: "Total Matches",
        headerProps: { className: "align-right" },
        cellProps: { className: "align-right" },
        cell: (info) => addCommasToNumber(info.getValue()),
        sortingFn: numberSortFn,
        sortDescFirst: true,
        size: 156,
      }),
      columnHelper.accessor("matchRate", {
        header: "Match Rate",
        headerProps: { className: "align-right" },
        cellProps: { className: "align-right" },
        cell: (info) => formatToPercent(info.getValue()),
        sortingFn: numberSortFn,
        sortDescFirst: true,
        size: 156,
      }),
      columnHelper.accessor("matchRateChange", {
        header: (
          <>
            <span className="wrap-text">Match Rate Change (MoM)</span>
            {renderMatchRateChangeInfoIcon()}
          </>
        ),
        headerProps: { className: "align-right" },
        cellProps: { className: "align-right" },
        cell: (info) => {
          const value = info.getValue();
          if (value === 0) return "--";

          const percentValue = formatToPercent(value);
          return value > 0 ? `+${percentValue}` : percentValue;
        },
        sortingFn: numberSortFn,
        sortDescFirst: true,
        size: 156,
      }),
    ];
  }, [ceMatchRateData]);

  function renderMatchRateChangeInfoIcon() {
    return (
      <Tooltip
        trigger={
          <span data-testId="match-rate-info-icon">
            <InfoOutlined size={20} />
          </span>
        }
        align="end"
        contentClass="tooltip__content--lg"
      >
        <div className="tooltip__content__title">
          MATCH RATE CHANGE
          <br />
          (MONTH OVER MONTH)
        </div>
        <span>
          This column shows the percentage point change in the selected month’s match rate compared
          to the prior month. If this month’s match rate is 20% and the Match Rate Change (MoM) is
          +5%, this indicates that last month, the match rate was 15%.
        </span>
      </Tooltip>
    );
  }

  return (
    <>
      <div className="card card--full-height" data-testid="ce-match-rate">
        <div className="card__title">Covered Entity Match Rate</div>
        <div className="action-bar">
          <MultiSelect
            label="Covered Entity"
            options={coveredEntities}
            selectedOptions={filters.coveredEntities}
            onChange={(selected) => handleFilterChange(filterTypes.COVERED_ENTITIES, selected)}
            onClear={() => handleFilterChange(filterTypes.COVERED_ENTITIES, [])}
            showValueInLabel
          />
          <MultiSelect
            label="Product"
            options={products}
            selectedOptions={filters.products}
            onChange={(selected) => handleFilterChange(filterTypes.PRODUCTS, selected)}
            onClear={() => handleFilterChange(filterTypes.PRODUCTS, [])}
          />
          <MultiSelect
            label="Match Rate"
            options={matchRates}
            selectedOptions={filters.matchRate}
            onChange={(selected) => handleFilterChange(filterTypes.MATCH_RATE, selected)}
            onClear={() => handleFilterChange(filterTypes.MATCH_RATE, [])}
            hideSearch={true}
          />
          {hasFiltersApplied && (
            <button className="btn btn--plain" onClick={clearFilters}>
              Clear All
            </button>
          )}
          <div className="action-bar__item--right">
            <SelectSearch
              name="month"
              testId="month-filter"
              options={months}
              defaultValue={months[0].value}
              value={filters.month || months[0].value}
              onChange={(selected) => handleFilterChange(filterTypes.MONTH, selected)}
            />
          </div>
        </div>
        <TableVirtualized
          data={ceMatchRateData || []}
          isLoading={isLoading}
          columns={columns}
          styleVariant="table--secondary table--layout-fixed"
          tableOptions={{
            initialState: { sorting: [{ id: "totalClaims", desc: true }] },
          }}
          noDataMessage="No results found that matched your search"
        />
      </div>
      <Notice details={ceMatchRateDataNotice} />
    </>
  );
};

CEMatchRate.propTypes = {
  coveredEntities: PropTypes.array,
  products: PropTypes.array,
  matchRates: PropTypes.array.isRequired,
  months: PropTypes.array,
};

CEMatchRate.defaultProps = {
  coveredEntities: [],
  products: [],
  months: [],
};

export default CEMatchRate;
