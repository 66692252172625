import React from "react";
import PropTypes from "prop-types";
import { Search as SearchIcon } from "./icons";
import colors from "../../frontend/stylesheets/common/theme/_colors.module.scss";

const Search = ({ className, name, placeholder, onChange }) => {
  return (
    <div className={`search__container ${className}`}>
      <span data-testId="search-icon">
        <SearchIcon color={colors.secondaryText} size={20} />
      </span>
      <input
        className="search__input"
        type="search"
        name={name}
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  );
};

Search.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

Search.defaultProps = {
  className: "",
  name: "",
  placeholder: "Search",
};

export default Search;
