import React, { useState } from "react";
import PropTypes from "prop-types";
import MaterialTextInput from "../../common/inputs/MaterialTextInput";

const SignIn = ({ resetPasswordUrl }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState("");

  const handleValidation = (event) => {
    if (!email || !password) {
      event.preventDefault();
      setErrors("Please enter your email and password.");
    }
  };

  return (
    <form action="/sessions" method="post" onSubmit={handleValidation} data-testid="sign-in-form">
      {errors && (
        <p className="t t--error" data-testid="error-message" style={{ fontSize: "14px" }}>
          {errors}
        </p>
      )}
      <MaterialTextInput
        id="email"
        name="email"
        value={email}
        label="Email Address *"
        variant="outlined"
        fullWidth
        onChange={(event) => setEmail(event.target.value)}
      />
      <MaterialTextInput
        type="password"
        id="password"
        name="password"
        value={password}
        label="Password *"
        variant="outlined"
        fullWidth
        onChange={(event) => setPassword(event.target.value)}
      />
      <button
        type="submit"
        style={{ marginTop: "24px", backgroundColor: "#0059F5" }}
        className="btn btn--variant-primary btn--size-lg btn--uppercase btn--full-width"
      >
        Login
      </button>
      <a
        href={resetPasswordUrl}
        className="t t--link"
        style={{ paddingTop: "16px", display: "inline-block", color: "#000" }}
      >
        Forgot Password?
      </a>
    </form>
  );
};

SignIn.propTypes = {
  resetPasswordUrl: PropTypes.string.isRequired,
};

export default SignIn;
