import React from "react";
import PropTypes from "prop-types";
import { Skeleton } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import CustomLegend from "./CustomLegend";
import NoResults from "../../common/NoResults";
import { barTheme, barTextStyle } from "./themes";
import textWidth from "../../utils/textWidth";
import colors from "../../../frontend/stylesheets/common/theme/_colors.module.scss";

const { white, primaryText, primaryBackground } = colors;

const PADDING = 16;

const { fontSize, fontFamily } = barTextStyle;

const getDataTypeConfig = (usePercentages, keys) => {
  const layers = ["grid", "axes", "bars", "markers", "legends"];

  const formatValues = (val, usePercentages) => {
    if (usePercentages) {
      return val === 0 ? val : `${val}%`;
    }

    return val;
  };

  const scaleValues = { type: "linear" };

  const axisBottomValues = {
    tickSize: 0,
    tickPadding: -8, // Negative value needed to nest below the bars with the correct padding
    format: (val) => formatValues(val, usePercentages),
  };

  return {
    valueScale: usePercentages ? { ...scaleValues, min: 0, max: 100 } : scaleValues,
    axisBottom: usePercentages
      ? { ...axisBottomValues, tickValues: [0, 50, 100] }
      : axisBottomValues,
    layers: usePercentages ? [...layers, (bars) => CustomBarLabel(bars, keys)] : layers,
  };
};

const CustomBarLabel = ({ bars }, keys) =>
  bars.map((bar) => {
    const isStacked = keys.length > 1;
    const barValue = `${bar.data.value}%`;
    const barWidth = bar.width;
    const barValueWidth = textWidth(barValue, barTextStyle, fontFamily);
    const barValueWidthIsGreaterThanBarWidth = barValueWidth + PADDING > barWidth;
    const barValueXoffset = barValueWidthIsGreaterThanBarWidth
      ? barWidth + PADDING / 2
      : barWidth - PADDING / 2;

    if (barValueWidthIsGreaterThanBarWidth && isStacked) return;

    return (
      <g
        key={bar.key}
        transform={`translate(${bar.x + barValueXoffset}, ${bar.y + bar.height / 2})`}
      >
        <text
          x={0}
          y={0}
          textAnchor={barValueWidthIsGreaterThanBarWidth && !isStacked ? "start" : "end"}
          dominantBaseline="middle"
          fill={barValueWidthIsGreaterThanBarWidth && !isStacked ? primaryText : white}
          style={{ fontSize: barTextStyle.fontSize }}
        >
          {barValue}
        </text>
      </g>
    );
  });

const HorizontalBar = ({
  data,
  usePercentages,
  showLegend,
  isLoading,
  indexBy,
  keys,
  colors,
  height,
  noDataMessage,
}) => {
  const legendProps = keys.map((key, i) => ({ label: key, color: colors[i] }));

  const leftMargin = Math.max(
    ...data.map((dataPoint) => textWidth(dataPoint[indexBy], fontSize, fontFamily)),
  );

  if (!isLoading && !data.length)
    return (
      <NoResults
        description={noDataMessage}
        height={height}
        imgWidth={80}
        backgroundColor={primaryBackground}
      />
    );

  if (isLoading) return <Skeleton variant="rounded" height={height} />;

  return (
    <>
      {showLegend && <CustomLegend legendProps={legendProps} />}
      <div className="bar" style={{ height: `${height}px` }}>
        <ResponsiveBar
          data={data}
          keys={keys}
          indexBy={indexBy}
          borderRadius={2}
          margin={{ top: 0, right: PADDING, bottom: 10, left: leftMargin + PADDING }}
          padding={0.3}
          layout="horizontal"
          indexScale={{ type: "band", round: true }}
          enableGridY={false}
          colors={colors}
          axisTop={null}
          axisRight={null}
          axisLeft={{
            tickSize: 0,
            tickPadding: PADDING,
          }}
          enableLabel={false}
          tooltip={() => null}
          theme={barTheme}
          labelSkipWidth={true}
          {...getDataTypeConfig(usePercentages, keys)}
        />
      </div>
    </>
  );
};

HorizontalBar.propTypes = {
  data: PropTypes.array,
  usePercentages: PropTypes.bool,
  showLegend: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  indexBy: PropTypes.string.isRequired,
  keys: PropTypes.arrayOf(PropTypes.string).isRequired,
  colors: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  height: PropTypes.number,
  noDataMessage: PropTypes.string,
};

HorizontalBar.defaultProps = {
  data: [],
  usePercentages: false,
  showLegend: false,
  height: 400,
  noDataMessage: "No data available at this time.",
};

export default HorizontalBar;
